<template>
    <h2>PARTI YORKIES OF THE GROVE: CLIENT PROJECT</h2>
    <div class="content">
        <h1>BRIEF</h1><br>
        <p>Students were required to develop a website using HTML/CSS (Javascript was optional) for a client within five weeks. The client presented their business to the class. Students wireframed, 
            performed usability testing, prototyped, and finally developed a final product that was presented to the client.</p><br>
        <h1>ABOUT THE CLIENT</h1><br>
        <p>The client is a yorkie breeder of a specific type of dog, the Parti Yorkie. Unlike regular yorkies, their coat is "inverted" with white being the main color with black/brown accents. 
            The client requested a website that can allow users to buy puppies from their site. They wanted a clean, classy look, since the main demographic for these dogs are middle-aged women.</p><br>
        <h1>ROLE</h1><br>
        <p>My role in our group of three was front/back end programming. All logos and mockups aside from the sitemap were created by my group members.</p><br>
        <h1>WHAT DID I LEARN?</h1><br>
        <p>This experience was my first look into projects where each member had a different role. Our groups were chosen to where each member had a different major that pertained to their role. With this, 
            I learned how to communicate with people in different areas of expertise. This project was also my first time running UX tests, and I learned a lot about how to give out these types of tests to 
            testers. Regarding coding the website, I learned how to create a CRUD system using PHP and further honed my JavaScript skills.</p><br>
        <h1 class="center">SITEMAP AND WIREFRAME</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/partiyorkies2.png"></div>
            <div><img src="../assets/partiyorkies3.png"></div>
        </div><br>
        <h1 class="center">MOCKUPS USING INVISION</h1><br>
        <div class="container" id="yorkiemockup">
            <div class="item"><img src="../assets/3Home.png"></div>
            <div class="item"><img src="../assets/3HomeABOUTHOVER.png"></div>
            <div class="item"><img src="../assets/3Puppies.png"></div>
            <div class="item"><img src="../assets/3Parents.png"></div>
            <div class="item"><img src="../assets/3About.png"></div>
            <div class="item"><img src="../assets/3ContactUs.png"></div>
        </div>
        <h1 class="center">FINAL SITE</h1><br>
        <div class="imagecontainer">
            <iframe id="yorkieiframe" src="/partiyorkies/index.html" height="800" width="1200"></iframe>
            <!--<div><iframe src="/partiyorkies/index.html" height="576" width="443"></iframe></div>-->
        </div><br><br>
        <!--<div class="imagecontainer wrap">
            <div><img src="../assets/partiyorkies4.png"></div>
            <div><img src="../assets/partiyorkies.png"></div>
            <div><img src="../assets/partiyorkies5.png"></div>
            <div><img src="../assets/partiyorkies6.png"></div>
            <div><img src="../assets/partiyorkies7.png"></div>
            <div><img src="../assets/partiyorkies8.png"></div>
            <div><img src="../assets/partiyorkies9.png"></div>
        </div><br>-->
        <!--<div class="imagecontainer" id="mobileyorkies">
            <div><img src="../assets/partiyorkies10.png"></div>
            <div><img src="../assets/partiyorkies11.png"></div>
        </div><br>-->
        <h1 class="center">JAVASCRIPT FEATURE SHOWCASE</h1><br>
        <div class="imagecontainer wrap">
            <p>Sticky header</p>
            <div><img src="../assets/sticky.gif"></div>
            <p>Dropdown</p>
            <div><img src="../assets/dropdown.gif"></div>
            <p>Mobile hamburger dropdown</p>
            <div><img src="../assets/mobiledropdown.gif"></div>
        </div><br>
        <h1 class="center">CRUD SYSTEM USING PHP</h1><br>
        <div class="imagecontainer wrap">
            <div><img src="../assets/php.gif"></div>
        </div><br>
    </div><br><br><br>
</template>
<style scoped>
.item > img {
    width:100%;
    border-radius:8px;
    display: block;
}
</style>